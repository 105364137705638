// @flow

import React from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { ShareButton, ShareList } from '@riseart/share';
import { WithStore } from 'shared_hocs/connect/withStore';
import { gtmSocialShare } from 'shared_data/providers/google/tagmanager/Actions';
import { share as ENUM_SHARE } from 'Enum';

type Props = {
  className?: ?string,
  contentKey: string,
  contentValues?: Object,
  url: ?string,
  image: string,
  description?: any,
  buttonText?: Object,
  children?: any,
  type?: string,
  theme?: string,
  isList?: boolean,
  hideLabels?: boolean,
};

/**
 * mapDispatchToProps
 * @props {Function} dispatch
 * @returns {Object} returns mapped actions with dispatch
 */
function mapDispatchToProps(dispatch: Function): Object {
  return {
    actionGtmSocialShare: (objectType: string, url: string) => (platform: string) =>
      dispatch(gtmSocialShare({ platform, objectType, objectUrl: url })),
  };
}

/**
 * Share
 *
 * @param {Props} props
 * @returns Share component
 */
export const Share = ({
  className = null,
  contentKey,
  contentValues = {},
  url,
  image,
  description = null,
  buttonText = null,
  children = null,
  type,
  theme,
  isList = false,
  hideLabels = false,
}: Props): React$Element<typeof WithStore> => {
  const { formatMessage } = useIntl();
  const parsedURL = queryString.parseUrl(url);
  const messages = {};
  const urlsByPlatform = {};
  const translatedSocialListItems = {};

  Object.keys(ENUM_SHARE.sources).forEach((i) => {
    const qParams = queryString.stringify({
      ...parsedURL.query,
      utm_source: i,
      utm_medium: ENUM_SHARE.sources[i].medium,
      utm_campaign: ENUM_SHARE.objects[contentKey].campaign,
      utm_content: contentValues && contentValues.id,
    });
    urlsByPlatform[i] = `${parsedURL.url}${qParams ? `?${qParams}` : ''}`;
    messages[i] = formatMessage(
      { id: `components.share.messages.${contentKey}.${i}` },
      { ...contentValues, link: urlsByPlatform[i] },
    );
    if (!hideLabels) {
      translatedSocialListItems[i] = formatMessage({ id: `components.share.options.${i}` });
    }
  });

  const Component = isList ? ShareList : ShareButton;

  return (
    <WithStore mapDispatchToProps={mapDispatchToProps}>
      {({ actionGtmSocialShare }) => (
        <Component
          className={className}
          buttonText={buttonText}
          url={url}
          urlsByPlatform={urlsByPlatform}
          image={image}
          description={description}
          onShare={actionGtmSocialShare(ENUM_SHARE.objects[contentKey].category, url)}
          theme={theme}
          type={type}
          translations={{
            title: formatMessage({ id: 'common.share' }),
            options: translatedSocialListItems,
            messages,
            emailSubject: formatMessage(
              {
                id: `components.share.messages.${contentKey}.emailSubject`,
              },
              { ...contentValues, link: url },
            ),
          }}
        >
          {children}
        </Component>
      )}
    </WithStore>
  );
};
